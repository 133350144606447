import React from 'react';

import { DateRange, DayModifiers, DayPicker, Matcher } from 'react-day-picker';

import { CustomCaption, withPropsCustomCaption } from './CustomCaption';
import {
  DayWithWeekNumber,
  withPropsDayWithWeekNumber,
} from './DayWithWeekNumber';
import { getYearOptions } from './helpers';

interface ReactDayPickerBaseProps {
  mode: 'single' | 'range';
  pickerFocused: boolean;
  numberOfMonths: number;
  futureYearsRange: number;
  renderCustomWeekNumberDayContents: boolean;
  disabledMatcher: Matcher[];
  isOutsideRange: (day: Date) => boolean | null;
}
interface ReactDayPickerSingleProps extends ReactDayPickerBaseProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  mode: 'single';
  selectedDay: Date | undefined;
  handleDateSelectSingle: (day: Date, { selected }: DayModifiers) => void;
  handleDateSelectRange?: never;
}
interface ReactDayPickerRangeProps extends ReactDayPickerBaseProps {
  mode: 'range';
  selectedDay: DateRange | undefined;
  handleDateSelectSingle: (day: Date, { selected }: DayModifiers) => void;
  handleDateSelectRange?: never; // (date: DateRange | undefined) => void; // handleDateSelectRange implies using the native library range select mode, but we have built out own using handleDateSelectSingle
}

export function ReactDayPicker({
  pickerFocused,
  selectedDay,
  handleDateSelectSingle,
  handleDateSelectRange,
  numberOfMonths = 1,
  futureYearsRange = 5,
  renderCustomWeekNumberDayContents = false,
  disabledMatcher,
  isOutsideRange,
  mode,
  ...props
}: ReactDayPickerSingleProps | ReactDayPickerRangeProps) {
  const yearOptions = getYearOptions(futureYearsRange);
  const { value: fromYear } = yearOptions.slice(-1)[0] ?? {};
  const { value: toYear } = yearOptions[0] ?? {};
  const weekStartsOn = 1;

  return mode === 'single' ? (
    <DayPicker
      {...props}
      data-test-id="day-picker"
      components={{
        DayContent: withPropsDayWithWeekNumber(
          DayWithWeekNumber,
          renderCustomWeekNumberDayContents,
          isOutsideRange,
        ),
        Caption: withPropsCustomCaption(CustomCaption, yearOptions),
      }}
      showWeekNumber
      mode={mode}
      weekStartsOn={weekStartsOn}
      initialFocus={pickerFocused}
      defaultMonth={selectedDay as Date}
      selected={selectedDay as Date}
      onDayClick={handleDateSelectSingle}
      numberOfMonths={numberOfMonths}
      captionLayout="dropdown-buttons"
      fromYear={fromYear}
      toYear={toYear}
      disabled={disabledMatcher}
    />
  ) : (
    <DayPicker
      {...props}
      data-test-id="day-picker"
      components={{
        DayContent: withPropsDayWithWeekNumber(
          DayWithWeekNumber,
          renderCustomWeekNumberDayContents,
          isOutsideRange,
        ),
        Caption: withPropsCustomCaption(CustomCaption, yearOptions),
      }}
      showWeekNumber
      mode={mode}
      weekStartsOn={weekStartsOn}
      initialFocus={pickerFocused}
      defaultMonth={(selectedDay as DateRange).from}
      selected={selectedDay as DateRange}
      onDayClick={handleDateSelectSingle}
      onSelect={handleDateSelectRange}
      numberOfMonths={numberOfMonths}
      captionLayout="dropdown-buttons"
      fromYear={fromYear}
      toYear={toYear}
      disabled={disabledMatcher}
    />
  );
}
