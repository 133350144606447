import UrlPattern from 'url-pattern';

import { stringifyUrl } from '../../utils/route';

const endpoints = {
  'schedule:shifts': '/v1/schedule/shifts/by-ids/groups/:groupId',
  'schedule:shift': '/v1/schedule/shifts/by-group/:groupId',
  'schedule:shift:add': '/v1/schedule/shifts',
  'schedule:shift:update': '/v1/schedule/shifts/:shiftId',
  'schedule:shift:draft': '/v1/schedule/shifts/draft',
  shiftTypes: '/v1/schedule/shifttypes/by-group/:groupId',
  staffCategories: '/v1/employee/staffcategories/by-group/:groupId',
  absenceTypes: '/v1/absence-types-settings/absence-types/by-group-id/:groupId',
  'schedule:leaveRequest:shifts':
    '/v1/schedule/leaverequests/by-group/:groupId/by-id/:leaveRequestId/shifts',
  'schedule:leaveRequests': '/v1/schedule/leaverequests/by-group/:groupId',
  'schedule:leaveRequests:approve':
    '/v1/schedule/leaverequests/by-group/:groupId/by-id/:leaveRequestId/approve',
  'schedule:leaveRequests:deny':
    '/v1/schedule/leaverequests/by-group/:groupId/by-id/:leaveRequestId/deny',
  'schedule:shiftRequests': '/v1/schedule/shiftrequests/by-group/:groupId',
  'schedule:shiftRequests:approve': '/v1/schedule/shiftrequests/approve',
  'schedule:shiftRequests:deny': '/v1/schedule/shiftrequests/deny',
  'schedule:shiftRequests:employees':
    '/v1/schedule/assignableemployees/by-shift-request-ids',
  'schedule:shiftBookingsRequests':
    '/v1/schedule/shift-booking-requests/by-group/:groupId',
  'schedule:shiftSwapsRequests':
    '/v1/schedule/shift-swap-requests/by-group/:groupId',
  'schedule:shiftAssignmentsRequests':
    '/v1/schedule/away-unit-requests/by-group/:groupId',
  'schedule:swapRequest:approve': '/v1/schedule/shiftrequests/approveswap',
  'schedule:noticeOfInterests':
    '/v1/schedule/noticesofinterest/by-group/:groupId',
  costCentre:
    '/v1/organisation/costcentres/by-group/:groupId/by-ids/:costCentreIds',
  costCentres: '/v1/organisation/costcentres/by-group/:groupId',
  'schedule:agreements': '/v1/schedule/agreements/by-group/:groupId',
  'schedule:agreementsForEmployee':
    '/v1/schedule/agreements/by-group/:groupId/by-employee/:employeeId',
  'schedule:projects': '/v1/organisation/projects/by-group/:groupId',
  'schedule:nominalHours':
    '/v1/schedule/nominalhours/by-group/:groupId/by-employee-ids',
  'schedule:nominalHours:schedulePeriod':
    '/v1/statistics/nominalhours/by-group/:groupId/schedulePeriod/by-employee-ids',
  'schedule:absence:absenceschedules':
    '/v1/absence-schedule/by-group-id/:groupId',
  'schedule:skills': '/v1/employee/skills/by-group/:groupId',
  'schedule:lockedPeriod': '/v1/schedule/locked-period/by-group/:groupId',
  'timetracker:transaction':
    '/v1/timetracking/timetrackers/by-group/:groupId/transactions',
  'timetracker:transaction:create':
    '/v1/timetracking/timetrackers/by-group/:groupId/transactions',
  'timetracker:transaction:update':
    '/v1/timetracking/timetrackers/by-group/:groupId/transactions/:transactionId',
  'timetracker:transaction:delete':
    '/v1/timetracking/timetrackers/by-group/:groupId/transactions/:transactionId',
  'timetracker:balance': '/v1/timetracking/timetrackers/by-group/:groupId',
  createpassword: '/v1/security/createpassword',
  resetpassword: '/v1/security/resetpassword',
  updatepassword: '/v1/security/updatepassword',
  validateUpdatepasswordHash: '/v1/security/reset/validate',
  'organisation:sections': '/v1/organisation/group/:groupId/sections',
  'organisation:groups': '/v1/organisation/groups',
  'settings:user': '/v1/settings/user',
  'settings:group': '/v1/settings/:groupKey',
  'settings:delete': '/v1/settings/delete/:groupKey/:key',
  'settings:update': '/v1/settings/update',
  baseschedule: '/v1/baseschedule/templates',
  'baseschedule:delete': '/v1/baseschedule/templates/:templateId',
  'baseschedule:fetch': '/v1/baseschedule/templates/by-group/:groupId',
  'baseschedule:scheduledHours':
    '/v1/baseschedule/templates/:templateId/scheduled-hours',
  'baseschedule:rename': '/v1/baseschedule/templates/:templateId',
  'baseschedule:copy': '/v1/baseschedule/templates/:templateId/copy',
  'baseschedule:shifts': '/v1/baseschedule/templates/:templateId/shifts',
  'baseschedule:shifts:batchcreate':
    '/v1/baseschedule/templates/:templateId/shifts/batch',
  'baseschedule:shifts:delete':
    '/v1/baseschedule/templates/:templateId/shifts/:shiftId',
  'baseschedule:shifts:batchdelete':
    '/v1/baseschedule/templates/:templateId/shifts/batch-delete',
  'baseschedule:shifts:create': '/v1/baseschedule/templates/:templateId/shifts',
  'baseschedule:shifts:update':
    '/v1/baseschedule/templates/:templateId/shifts/:shiftId',
  'baseschedule:shifts:draft':
    '/v1/baseschedule/templates/:baseScheduleId/shifts/draft',
  'baseschedule:id': '/v1/baseschedule/templates/:baseScheduleId',
  'people:fetch': '/v1/employee/by-group/:groupId',
  'people:customerGroups:fetch':
    '/v1/organisation/group/:groupId/customer-groups/by-ids/:customerGroupId',
  'agreementTemplates:fetch':
    '/v1/employee/agreement-templates/by-group/:groupId',
  'person:fetch': '/v1/employee/:personId',
  'managers:fetch': '/v1/employee/managers/by-group/:groupId',
  'baseschedule:rollout': '/v1/baseschedule/templates/:baseScheduleId/rollout',
  'baseschedule:rollout:action':
    '/v1/baseschedule/templates/:baseScheduleId/rollout/:rolloutId/errors',
  'baseschedule:rollout:warnings':
    '/v1/baseschedule/templates/:baseScheduleId/rollout/:rolloutId/errors',
  'baseschedule:rollout:retry':
    '/v1/baseschedule/templates/:baseScheduleId/rollout/:rolloutId',
  'people:customerUnitGroups:fetch':
    '/v1/organisation/group/:groupId/sharable-unit-groups',
  'badgeNumber:fetch': '/v1/employee/badge-number/by-group/:groupId',
  'person:add': '/v1/employee/',
  'person:update': '/v1/employee/:personId',
  'person:staffCategory': '/v1/employee/staffcategories',
  'agreementData:fetch': '/v1/employee/agreements/:agreementId',
  'agreement:add': '/v1/employee/agreements',
  'agreementTemplateData:fetch': '/v1/employee/agreement-templates/:templateId',
  'agreement:update': '/v1/employee/agreements/:agreementId',
  'defaultTimeRules:fetch': '/v1/employee/rules-for-hours/by-group/:groupId',
  'calculationBreakRules:fetch':
    '/v1/employee/project-breaks/by-group/:groupId',
  'shiftPatternAvailabilityTemplate:fetch':
    '/v1/employee/avail-templates/:id/availabilities',
  'skills:fetch': '/v1/employee/skills/by-group/:groupId',
  'skills:add': '/v1/employee/skills',
  'skills:categories:fetch': '/v1/employee/skill-categories/by-group/:groupId',
  'skills:templates:fetch': '/v1/employee/skill-sets/by-group/:groupId',
  'skills:templates:add': '/v1/employee/skill-sets',
  'skills:categories:add': '/v1/employee/skill-categories',
  'skills:templates:edit': '/v1/employee/skill-sets/:templateId',
  'skills:categories:edit': '/v1/employee/skill-categories/:categoryId',
  'skills:edit': '/v1/employee/skills/:skillId',
  'skills:templates:delete': '/v1/employee/skill-sets/:templateId',
  'skills:categories:delete': '/v1/employee/skill-categories/:categoryId',
  'skills:delete': '/v1/employee/skills/:skillId',
  'agreement:delete': '/v1/employee/agreements/:agreementId',
  'agreement:sign':
    '/v1/employee/agreements/:agreementId/pdf-template/:pdfTemplateId/start-signing-process?unitGroupId=:groupId',
  'agreement:preview:sign':
    '/v1/employee/agreements/:agreementId/pdf-template/:pdfTemplateId/preview-sign?unitGroupId=:groupId',
  'agreement:preview:pdf':
    '/v1/employee/agreements/:agreementId/pdf-template/:pdfTemplateId/preview-pdf?unitGroupId=:groupId',
  'agreement:refresh:status':
    '/v1/employee/agreements/:agreementId/refresh-status?groupId=:groupId&unitGroupId=:unitGroupId',
  'agreement:signed:pdf':
    '/v1/employee/agreements/:agreementId/documents/:documentId/download-signed-pdf?groupId=:groupId&unitGroupId=:unitGroupId',
  'agreement:reset':
    '/v1/employee/agreements/:agreementId/reset?groupId=:groupId&unitGroupId=:unitGroupId',
  'agreement:withdraw':
    '/v1/employee/agreements/:agreementId/withdraw?groupId=:groupId&unitGroupId=:unitGroupId',
  'people:additionalFields:fetch':
    '/v1/employee/additional-fields/by-group/:groupId',
};

/*
 This function should not exist. It does exist because,
 1. Previous lib had skipNulls which I do not know how to test if it was helping something
 2. Some callers of getRequestUrl sets redux getState function as a query param which is obviously wrong, previous library tolerated it.
    It must better be fixed at the root, but I do not know how to find all possible bugs around it either apart from writing tests obviously
*/

const skipInvalid = (object) =>
  Object.entries(object)
    .filter(
      ([, value]) =>
        value !== undefined && value !== null && typeof value !== 'function',
    )
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const getRequestUrl = (key, params = {}, queryParams = {}) => {
  const url = new UrlPattern(endpoints[key]).stringify(params);
  return stringifyUrl(url, skipInvalid(queryParams));
};

const defaultMessage = 'Something went wrong, please try again.';
const defaultStatusMessages = {
  403: 'default.accessDenied',
};

const getArrayMessages = (error, messages) =>
  error.body.reduce(
    (acc, { field, message }) => ({
      ...acc,
      [field || 'mainMessage']: messages[message] || message,
    }),
    {},
  );

const getNonArrayMessages = (error = {}, messages = {}) => {
  const message = error.body && error.body.message;

  return {
    mainMessage:
      messages[message] ||
      defaultStatusMessages[error.status] ||
      defaultMessage,
  };
};

export const retrieveMessagesMap = (error = {}, messages = {}) => {
  if (!(error.body instanceof Array)) {
    return getNonArrayMessages(error, messages);
  }

  return getArrayMessages(error, messages);
};

export const transformApiDefaultState = {
  errors: {},
  isAttempted: false,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const isForbidden = (error) => {
  return error ? error.status === 403 : false;
};

export const retrieveMessages = (errors) => {
  let messagesBody = [];
  if (errors && errors.body instanceof Array) {
    messagesBody = errors.body.map(({ field, message }) => {
      return {
        id: field || message.split('.')[2],
        message,
      };
    });
  } else {
    const { mainMessage } = getNonArrayMessages(errors);
    messagesBody = [{ id: 'mainMessage', message: mainMessage }];
  }

  return messagesBody;
};

// This exposes superagents abort function on returned promise.
export const patchPromiseWithAbort = (req, promise) => {
  if (req && typeof req.abort === 'function') {
    promise.externalAbort = () => req.abort();
  }

  return promise;
};
