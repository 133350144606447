import { CallParams, SuperAgentApi } from '@shared/containers/hooks/api/types';

import { retrieveMessagesMap, transformApiDefaultState } from './index-old';

export * from './index-old';

// wraps and API and transforms the response errors and adds status booleans
export const transformApi =
  <P extends CallParams, T>(api: SuperAgentApi<T, P>) =>
  (params: P) => {
    const calledApi = api(...params);
    const promiseForReturn = calledApi
      .then((res) => ({
        ...transformApiDefaultState,
        data: res?.body,
        isAttempted: true,
        isSuccess: true,
      }))
      .catch((error) => {
        const result = {
          ...transformApiDefaultState,
          errors: retrieveMessagesMap(error),
          originalError: error,
          isAttempted: true,
          isError: true,
        };

        throw result;
      });

    return [promiseForReturn, calledApi.externalAbort] as const;
  };
